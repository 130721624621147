.js_event{}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Regular.ttf);
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Italic.ttf);
    font-style: italic;
}
@font-face {
    font-family: Roboto;
    src: url(./fonts/Roboto-Bold.ttf);
    font-weight: bold;
}

.rtl {
    direction: rtl;
}

.pwa_container p,
.pwa_container div,
.pwa_container span,
.pwa_container h1,
.pwa_container h2 {
    font-family: "Roboto" !important;
}

.btn__link,
.btn__no,
.btn__yes,
.comment__icon,
.loading--loader {
    text-align: center
}

.pwa_container {
    width: 100%;
    max-width: 650px;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 680px;
    z-index: 5;
}

.android_frame {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, 0);
    z-index: 1;
}

.android_frame img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.contentSection {
    background: #fff;
    margin: 22px 48px 19px 45px;
    height: 100%;
    padding: 10px;
    border-radius: 30px;
    position: relative;
    overflow-y: scroll;
    z-index: 3;
    overflow-x: hidden;
}

.iconCancel img,
.iconClose img,
.menu img,
.title__avatar img,
.top__line_left img,
.zoom img {
    width: 100%;
    object-fit: cover;
    height: 100%
}

.btn_container,
.center,
.info_line,
.top {
    position: relative
}

.loader div{
    box-sizing: border-box
}

.loader,
.loader div {
    transform: translate(-50%, 0)
}
body,
html {
    width: 100%;
    font-family: "Roboto", sans-serif;
}

.container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 17px 0 50px;
    background:  #fff;
}

.top {
    padding-bottom: 25px;
}

.top::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -22px;
    right: -22px;
    height: 1px;
    background-color: #ddd;
}

.top__line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px
}

.top__line_left {
    width: 14px;
    height: 14px
}

.rtl .top__line_left {
    transform: rotate(180deg);
}

.menu,
.zoom {
    height: 16px
}

.top__line_right {
    display: flex
}

.zoom {
    width: 16px;
    margin-right: 34px
}

.rtl .zoom {
    margin-left: 34px;
    margin-right: 0px;
}

.menu {
    box-sizing: border-box;
    padding-left: 15px;
}

.rtl .menu {
    padding-right: 15px;
    padding-left: 0;
}

.title {
    display: flex;
    padding-left: 10px;
    margin-bottom: 20px
}

.rtl .title {
    padding-right: 10px;
    padding-left: 0;
}

.main_name,
.name_down {
    margin-bottom: 5px;
    font-style: normal
}

.title__avatar {
    width: 74px;
    height: 74px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.25);
}

.rtl .title__avatar {
    margin-left: 20px;
    margin-right: 0; 
}

.main_name {
    color: #202124;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 8px;
}

.name_down {
    height: 15px;
    color: var(--main-color);
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 12px;
    text-transform: capitalize
}

.title__name .name_down:last-of-type {
    font-size: 12px;
    color: #5F6368;
}

.info_line {
    display: flex;
    align-items: flex-end;
    margin-bottom: 17px;
    overflow-x: scroll
}

.info_line::-webkit-scrollbar {
    display: none;
}

.info_line {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 25px;
    overflow-x: scroll;
}

.infoElement {
    position: relative;
    width: 33%;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-items: stretch;
}

.infoElement::after {
    content: "";
    position: absolute;
    top: 43%;
    bottom: 17%;
    right: 0;
    width: 2px;
    background-color: #ddd;
}

.rtl .infoElement:first-child::after {
    content: "";
    display: none;
}

.infoElement:last-child::after {
    content: "";
    display: none;
}

.rtl .infoElement:last-child::after {
    content: "";
    display: block;
}



.info_line__left,
.info_line__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end
}

.info_line__left p:first-child {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #202124;
    margin-bottom: 4px;
}

.center p,
.info_line__left p:nth-child(2),
.info_line__right p:nth-child(2) {
    color: #3e3e3e;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    margin-top: 1px;
}

.btn__link {
    font-size: 15px;
    font-weight: 600
}


.description h1 {
    font-size: 18px;
    font-weight: 600;
    color: #202124;
}

.description p {
    color: #000;
}

.info_line__center {
    display: flex;
    align-items: center
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 120px
}

.center__icon {
    margin-bottom: 3px;
    width: 20px;
    height: 20px
}

.center__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.description h1,
.description p {
    margin-bottom: 20px;
    font-style: normal
}

.age_user_app {
    padding-block: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    margin-bottom: 3px;
    color: #202124;
}

.amountInstall {
    color: #202124;
    margin-bottom: 5px;
}

.categories {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 20px;
}

.categories::-webkit-scrollbar {
    display: none;
}

.category__content {
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;
    color: #484848;
}

.category {
    border: 1px solid #484848;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.rtl .category {
    margin-left: 10px;
    margin-right: 0;
}

.btn_container {
    min-height: 37px
}

.btn {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 39px;
    background: var(--main-color);
    cursor: pointer
}

.data_security__content p:last-child,
.rating__item:last-child,
.scroll_1 {
    margin-bottom: 0
}

.rtl .scroll_1 {
    direction: rtl;
}

.btn__link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    font-weight: normal;
    font-style: normal;
    line-height: 37px
}

.scroll__container_1,
.scroll__container_2 {
    display: flex;
    width: 100%;
    height: 195px;
    overflow-y: hidden;
    padding-left: 10px;
    overflow-x: scroll
}

.rtl .scroll__container_1 {
    padding-right: 10px;  
    padding-left: 0;
    flex-direction: row-reverse;
}

.scroll__container_1::-webkit-scrollbar {
    display: none
}

.scroll__container_1:last-child {
    padding-right: 12px;
    padding-top: 21px;
}

.rtl .scroll__container_1:last-child {
    padding-right: 12px;
    padding-left: 0;
}

.item_1 {
    margin-right: 10px;
    position: relative;

    .iconRemoveBanner {
        position: absolute;
        width: 25px;
        height: 25px;
        object-fit: contain;
        border-radius: 100%;
        cursor: pointer;
        top: -15px;
        left: 97%;
        z-index: 6;
    }
}

.rtl .item_1 {
    margin-right: 0;
    margin-left: 10px;
}

.item_1 img {
    width: 90px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .25);
    object-fit: contain;
    border-radius: 10px
}

.data_security h1,
.description h1,
.rewievs h1 {
    line-height: 18px
}

.description p {
    font-size: 14px;
    line-height: 15px;
    white-space: pre-wrap;
    line-height: 1.20rem;
}

.description ol {
    margin: 0;
    padding-left: 20px
}

.description ol li,
.instruction p,
.stars__data {
    font-style: normal;
    line-height: 13px
}

.comment__content p {
    font-size: 14px;
    line-height: 1.2rem;
    color: #000;
}

.data_security h1,
.instruction h1,
.rewievs h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
    color: #202124;
}

.data_security,
.instruction {
    margin-bottom: 20px;
}

.instruction h1 {
    line-height: 15px
}

.scroll_2 {
    margin-left: 12px;
    margin-bottom: 20px
}

.scroll__container_2::-webkit-scrollbar {
    display: none
}

.scroll__container_2:last-child {
    padding-right: 5px
}

.item_2 {
    margin-right: 2px
}

.item_2 img {
    width: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 7px
}

.data_security__content {
    padding: 26px;
    border-radius: 7px;
    border: 1px solid #c8c8c8
}

.data_security__content p,
.rewievs__content {
    font-style: normal
}

.data_security__content p {
    color: #484848;
    font-size: 14px;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
    margin-bottom: 15px
}

.data_security p img {
    margin-right: 15px
}

.data_security_rtl img {
    margin-left: 15px !important;
    margin-right: 0 !important;
}

.rewievs {
    margin-bottom: 20px;
}

.rewievs__content {
    margin-bottom: 20px
}

.rating {
    display: flex;
}

.rating__item,
.stars {
    margin-bottom: 6px;
    display: flex;
}

.stars__content {
    font-size: 14px;
    color: #000;
}

.rating__left {
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    min-width: 69px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 22px;
    color: #202124;
}

.rtl .rating__left {
    margin-left: 22px;
    margin-right: 0;
}

.comment__icon,
.item__number {
    font-style: normal;
    color: #000;
}

.rating__right {
    width: 80%
}

.rating__item {
    height: 11px
}

.item__number {
    margin-right: 15px;
    font-size: 15px;
}

.rtl .item__number {
    margin-left: 15px; 
    margin-right: 0; 
}

.item__img {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 21px;
}

.item__img progress {
    width: 100%;
    height: 10px;
    border: none;
    border-radius: 5px;
    background-color: #e0e0e0;
    overflow: hidden;
}
 
.item__img progress::-webkit-progress-bar {
    background-color: #e0e0e0;
    border-radius: 5px;
}

.item__img progress::-webkit-progress-value {
    background-color: var(--main-color);
    border-radius: 5px;
} 

.star__img {
    margin-right: 4px
}

.rtl .star__img {
    margin-left: 4px;
    margin-right: 0;
}

.comment {
    margin-bottom: 20px
}

.comment__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
}

.comment__top_left {
    display: flex;
    align-items: center;
}

.comment__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #425963;
    margin-right: 13px;
    line-height: 30px;
    color: #000;
    font-size: 15px
}

.popup_menu {
    display: none;
    border-radius: 5px;
    background-color: #e1eef7;
    box-shadow: 0px 0px 8px 1px rgba(117,117,117,0.5);
}

.popup_menu__container {
    padding: 10px;
}

.popup_menu__container:active {
    background-color: #c2e7ff;
}

.popup_menu__content {
    font-size: 14px;
    cursor: pointer;
    color: #202124;
}

.btn__no,
.btn__yes,
.comment__title,
.counter {
    font-style: normal
}

.comment__icon img {
    border-radius: 100%;
    width: 100%;
    height: 100%
}

.comment__title {
    font-size: 14px;
    font-weight: 500;
    color: #202124
}

.comment__stars {
    display: flex;
    margin-bottom: 12px;
}

.stars__centering {
    margin-bottom: 0;
    margin-right: 6px;
    align-items: center;
}

.stars__data__centering {
    line-height: 100%;
    font-size: 12px;
}

.comment_review {
    font-size: 12px;
    margin: 16px 0 10px 0;
    color: #000;
}

.comment_review span {
    color: #000;
}

.comment_question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment_question p {
    color: #000
}

.comment_question__content {
    font-style: normal;
    line-height: 13px;
    font-size: 14px;
}

.btn_container {
    display: flex;
    align-items: center;

}

.btn_container div {
    color: #000;
}

.comment_btn {
    border: 1px solid #75747a;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    height: 29px;
    padding-inline: 5px;
    font-weight: 500;
    line-height: 29px;
    font-size: 14px;
}

.comment_btn:first-child {
    margin-right: 8px;
}

.rtl .comment_btn:first-child {
    margin-left: 8px;
    margin-right: 0;
}

.comment_btn.active {
    background-color: #c2e7ff;
    border: none;
}

.rtl .recommended_apps {
}

.recommend__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-right: 22px;
    margin-bottom: 20px;
    color: #202124;
}

.rtl .recommend__title {
    margin-left: 22px;
    margin-right: 0;
}

.recommended_scroll__container {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
}

.recommended_scroll__container::-webkit-scrollbar {
    display: none
}

.recommended_scroll__container:last-child {
    padding-right: 12px
}

.rtl .recommended_scroll__container:last-child {
    padding-left: 12px;
    padding-right: 0;
}

.recommended_app {
    min-width: 130px;
    max-width: 130px;
    margin-right: 10px;
}

.rtl .recommended_app {
    margin-left: 10px;
    margin-right: 0;
}

.recommended_app__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
}

.recommended_app__icon img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .25);
    width: 120px;
    height: 120px;
    object-fit: contain;
    border-radius: 12px
}

.recommended_app__name {
    font-size: 12px;
    font-style: normal;
    margin-bottom: 5px;
    color: #000;
}

.recommended_app__rating {
    font-size: 12px;
    font-style: normal;
    position: relative;
    margin-right: 5px;
    color: #000;
}

.rtl .recommended_app__rating {
    margin-left: 5px;
    margin-right: 0;
}

.recommended_app__rating::after {
    content: '';
    display: inline-block;
    margin-left: 3px;
    width: 9px;
    height: 9px;
    background: url('images/icon_star_black.svg') no-repeat center;
}

.rtl .recommended_app__rating::after {
    margin-right: 3px;
    margin-left: 0;
}

.popup_rewiew {
    display: none;
    justify-content: center;
    width: 100%;
    position: fixed;
    bottom: 20px;
}

.popup_rewiew__container {
    background-color: #1F1F1F;
    border-radius: 5px;
    padding: 20px;
    width: 90%;
    max-width: 600px;
}

.popup_rewiew__content {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
}

.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .5);
    z-index: 1
}

.popup__container {
    width: 70%;
    max-width: 250px;
    height: 80%;
    max-height: 500px;
    position: relative
}

.loader,
.loader div,
.popup-install,
.preloader {
    position: absolute
}

.iconCancel,
.iconClose {
    position: absolute;
    top: -30px;
    right: -10px;
    width: 30px;
    height: 30px
}

.popup__content,
.popup__content img {
    width: 100%;
    height: 100%
}

.popup__content img {
    border-radius: 10px;
    object-fit: contain;
    object-position: top
}

.loader {
    width: 37px;
    height: 37px;
    top: 0;
    left: 50%
}

.loader div {
    display: block;
    width: 30px;
    height: 30px;
    margin: 2px;
    border: 2px solid #f96703;
    border-radius: 50%;
    animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite loader;
    border-color: #fff transparent transparent
}

.loader div:first-child {
    animation-delay: -.45s
}

.loader div:nth-child(2) {
    animation-delay: -.3s
}

.loader div:nth-child(3) {
    animation-delay: -.15s
}

@keyframes loader {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.preloader {
    display: block;
    top: 37px;
    left: 10px;
    transform: none;
    width: 74px;
    height: 74px
}

.counter {
    display: none;
    align-items: center;
    font-size: 10px;
    font-weight: 600
}

.counter p {
    margin-left: 3px
}

.popup_install {
    background: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.popup_install__item {
    position: relative;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
    margin: 0 10px;
    border-radius: 10px;
    width: 80%;
    text-align: center
}

.popup_install__item p {
    font-size: 15px;
}

.popup_install__item:first-child {
    line-height: 1.2rem;
}

.click-imitation {
    margin-top: 20px
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 15px;
    font-weight: 600
}

.loading:after {
    content: " .";
    animation: 1s steps(5, end) infinite dots
}

.imageVerifyDeveloper {
    display: inline-block;
    margin-left: 2px;
    object-fit: contain;
    width: 15px;
    height: 15px;
}

@keyframes dots {

    0%,
    20% {
        color: transparent;
        text-shadow: .25em 0 0 transparent, .5em 0 0 transparent
    }

    40% {
        color: #fff;
        text-shadow: .25em 0 0 transparent, .5em 0 0 transparent
    }

    60% {
        text-shadow: .25em 0 0 #fff, .5em 0 0 transparent
    }

    100%,
    80% {
        text-shadow: .25em 0 0 #fff, .5em 0 0 #fff
    }
}

.PreviewReview {
    width: 100%;
    margin-bottom: 25px;

    .PreviewReview__InfoUser {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .PreviewReview__Container_Logo {
        display: inline-block;
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
    }

    .PreviewReview__Symbol {
        width: 100%;
        height: 100%;
        font-size: 26px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        font-family: Roboto, sans-serif !important;
    }

    .PreviewReview__Logo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }

    .PreviewReview__NameDeveloper {
        font-family: Roboto, sans-serif !important;
        font-size: 15px;
        margin-left: 15px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: normal;
        color: #000;
    }


    .PreviewReview__TextReview,
    .PreviewReview__TextResponseDeveloper {
        font-family: Roboto, sans-serif !important;
        font-size: 14px;
        line-height: 17px;
    }

    .PreviewReview__TextReview {
        margin-bottom: 15px;
        color: #000;
    }

    .PreviewReview__TextResponseDeveloper {
        background: rgb(248,249,250);
        padding: 15px 10px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        color: #000 !important;
    }
}

.rtl .PreviewReview__NameDeveloper {
    margin-right: 15px;
    margin-left: 0;
}

.DeveloperResponse_title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    span {
        color: #000 !important;
    }
}

.RatingDate {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
        color: #000;
        display: inline-block;
        margin-left: 15px;
        font-size: 14px;
    }
}

.rtl .RatingDate {
    span {
        margin-right: 15px;
        margin-left: 0;
    }
}

.BtnShowAllReview {
    color: var(--main-color);
    font-size: 14px;
    margin-bottom: 40px;
    cursor: pointer;
}

.rtl .BtnShowAllReview {
    direction: rtl;
}
